import styled from 'styled-components'

export const LSWorkWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const LSWorkCommand = styled.div`
    display: flex;
`

export const LsWorkWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`

export const LSWorkFolder = styled.div`
    margin-right: 48px;
    color: #3498db;
`
