import React from 'react'
import * as Styled from './styled'
import { getTerminalInfoCurrentLocation } from '@redux/selectors'
import { useSelector } from 'react-redux'

const TerminalHeader = () => {
    const terminalLocation = useSelector(
        getTerminalInfoCurrentLocation
    )

    return (
        <Styled.TerminalHeaderWrapper>
            <Styled.TerminalHeaderDots />
            <Styled.TerminalHeaderText>
                {terminalLocation}
            </Styled.TerminalHeaderText>
        </Styled.TerminalHeaderWrapper>
    )
}

export default TerminalHeader
