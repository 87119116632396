import React from 'react'
import * as Styled from './styled'
import Prefix from '@components/prefix'
import Cursor from '@components/cursor'

const LastRow = () => {
    return (
        <Styled.LastRowWrapper>
            <Prefix />
            <Cursor />
        </Styled.LastRowWrapper>
    )
}

export default LastRow
