import styled from 'styled-components'
import Typist from 'react-typing-animation'

export const CommandWrapper = styled.div`
    display: flex;
`

export const CommandText = styled(Typist)`
    display: flex;
`
