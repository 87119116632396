import React from 'react'
import * as Styled from './styled'
import Prefix from '@components/prefix'
import Command from '@components/command'
import { useSelector, useDispatch } from 'react-redux'
import { isLsHomeBodyShown } from '@redux/selectors'
import { toggleCommand, toggleBody } from '@redux/actions'

const RowLS = () => {
    const isLSContentShown = useSelector(isLsHomeBodyShown)
    const dispatch = useDispatch()

    return (
        <Styled.RowLSWrapper>
            <Styled.RowLSWLineOneWrapper>
                <Prefix />
                <Command
                    text="ls"
                    onFinish={() => {
                        dispatch(toggleBody('lsHome'))
                        dispatch(toggleCommand('catAbout'))
                    }}
                />
            </Styled.RowLSWLineOneWrapper>

            {isLSContentShown && (
                <Styled.RowLSWLineTwoWrapper>
                    <Styled.RowLSTextFile>
                        About.txt
                    </Styled.RowLSTextFile>
                    <Styled.RowLSTextFile>
                        Social_Links.txt
                    </Styled.RowLSTextFile>
                    <Styled.RowLSFolder>
                        Working_Experience/
                    </Styled.RowLSFolder>
                </Styled.RowLSWLineTwoWrapper>
            )}
        </Styled.RowLSWrapper>
    )
}

export default RowLS
