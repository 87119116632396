import styled from 'styled-components'

export const TerminalHeaderWrapper = styled.div`
    width: 100%;
    height: 28px;
    background: linear-gradient(0deg, #d8d8d8, #ececec);
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`

export const TerminalHeaderDots = styled.div`
    position: absolute;
    left: 12px;
    top: 8px;
    width: 12px;
    height: 12px;
    background: #f95c5b;
    border-radius: 100%;
    box-shadow: 0 0 0 1px #da3d42, 22px 0 0 0 #fabe3b,
        22px 0 0 1px #ecb03e, 44px 0 0 0 #38cd46,
        44px 0 0 1px #2eae32;
`

export const TerminalHeaderText = styled.div`
    text-align: center;
    color: #4c3436;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0 auto;
    user-select: none;
`
