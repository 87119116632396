import styled from 'styled-components'

export const TerminalWindowWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    width: 90vw;
    max-width: 1200px;
    height: 90vh;
    min-height: 300px;
    overflow: hidden;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05),
        0 0 30px 1px rgba(0, 0, 0, 0.2);
`
