import React from 'react'
import * as Styled from './styled'
import Command from '@components/command'
import Prefix from '@components/prefix'
import { useDispatch, useSelector } from 'react-redux'
import { isCatAboutBodyShown } from '@redux/selectors'
import { toggleBody, toggleCommand } from '@redux/actions'

const CatAbout = () => {
    const dispatch = useDispatch()

    const isAboutShown = useSelector(isCatAboutBodyShown)

    return (
        <Styled.CatAboutWrapper>
            <Styled.CatAboutCommand>
                <Prefix />
                <Command
                    text="cat About.txt"
                    onFinish={() => {
                        dispatch(toggleBody('catAbout'))
                        dispatch(toggleCommand('catSocial'))
                    }}
                />
            </Styled.CatAboutCommand>
            {isAboutShown && (
                <Styled.CatAboutText>
                    Highly skilled JavaScript developer with
                    more than 8 years of working experience
                    in the IT industry.
                    <br />
                    <br />
                    My experience in this sphere started
                    with IT Tech Support spec and NOC
                    Engineer in Telecommunications and VoIP
                    companies to a sysadmin in bookmaking
                    companies.
                    <br />
                    <br />
                    Being in the tech sphere and having a
                    great desire to become a Game Developer,
                    I started coding 👨‍💻
                    <br />
                    <br />I went on with JavaScript and what
                    happened next you can check{' '}
                    <Styled.CatAboutHref
                        href="http://api.mamikonyan.io/assets/cv.pdf"
                        target="_blank"
                    >
                        in my CV
                    </Styled.CatAboutHref>{' '}
                    📝
                    <br />
                    <br />
                    BTW, Make sure to check out (or even
                    contribute){' '}
                    <Styled.CatAboutHref
                        href="https://scarves.mamikonyan.io/"
                        target="_blank"
                    >
                        my football scarves collection
                    </Styled.CatAboutHref>{' '}
                    ⚽
                </Styled.CatAboutText>
            )}
        </Styled.CatAboutWrapper>
    )
}

export default CatAbout
