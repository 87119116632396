import { createSlice } from '@reduxjs/toolkit'
import { COMMANDS } from '@data'

const initialState = {
    location: {
        root: '~',
        current: '~',
    },
    commands: COMMANDS,
}

const terminalInfoSlice = createSlice({
    name: 'terminal-info',
    initialState,
    reducers: {
        changeLocation: {
            reducer: (state, { payload }) => {
                state.location.current = payload
            },
            prepare: payload => ({ payload }),
        },
    },
})

export const { changeLocation } = terminalInfoSlice.actions
export const { reducer: terminalInfo } = terminalInfoSlice
