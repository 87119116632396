import React from 'react'
import * as Styled from './styled'
import Prefix from '@components/prefix'
import Command from '@components/command'
import { useDispatch, useSelector } from 'react-redux'
import { isLsWorkingExperienceBodyShown } from '@redux/selectors'
import { toggleBody, toggleCommand } from '@redux/actions'

const LsWork = () => {
    const dispatch = useDispatch()

    const isFolders = useSelector(
        isLsWorkingExperienceBodyShown
    )

    return (
        <Styled.LSWorkWrapper>
            <Styled.LSWorkCommand>
                <Prefix />
                <Command
                    text="ls"
                    onFinish={() => {
                        dispatch(
                            toggleBody(
                                'lsWorkingExperience'
                            )
                        )
                        dispatch(toggleCommand('cdHome'))
                    }}
                />
            </Styled.LSWorkCommand>
            {isFolders && (
                <Styled.LsWorkWrapper>
                    <Styled.LSWorkFolder>
                        Questrade/
                    </Styled.LSWorkFolder>
                    <Styled.LSWorkFolder>
                        PicsArt/
                    </Styled.LSWorkFolder>
                    <Styled.LSWorkFolder>
                        apolloBytes(devlix)/
                    </Styled.LSWorkFolder>
                    <Styled.LSWorkFolder>
                        Renderforest/
                    </Styled.LSWorkFolder>
                    <Styled.LSWorkFolder>
                        EPAM/
                    </Styled.LSWorkFolder>
                  <Styled.LSWorkFolder>
                    Rain/
                  </Styled.LSWorkFolder>
                </Styled.LsWorkWrapper>
            )}
        </Styled.LSWorkWrapper>
    )
}

export default LsWork
