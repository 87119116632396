import styled from 'styled-components'

export const CatAboutWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const CatAboutCommand = styled.div`
    display: flex;
`

export const CatAboutText = styled.p``

export const CatAboutHref = styled.a`
    color: #9980fa;
`
