import styled from 'styled-components'

export const RootWrapper = styled.div`
    display: flex;
    background-image: url('./background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
`
