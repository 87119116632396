import React from 'react'
import * as Styled from './styled'
import Cursor from '../cursor'

const Command = ({
    text,
    speed = 200,
    onFinish = () => {},
}) => {
    return (
        <Styled.CommandWrapper>
            <Styled.CommandText
                cursor={<Cursor />}
                speed={speed}
                onFinishedTyping={onFinish}
            >
                {text}
            </Styled.CommandText>
        </Styled.CommandWrapper>
    )
}

export default Command
