import React from 'react'
import * as Styled from './styled'
import Prefix from '@components/prefix'
import Command from '@components/command'
import { useDispatch, useSelector } from 'react-redux'
import { isHomeCowsayBodyShown } from '@redux/selectors'
import { toggleBody, toggleCommand } from '@redux/actions'

const Cowsay = () => {
    const dispatch = useDispatch()

    const isPhrase = useSelector(isHomeCowsayBodyShown)

    return (
        <Styled.CowsayWrapper>
            <Styled.CowsayCommand>
                <Prefix />
                <Command
                    text="cowsay thanks dude!"
                    onFinish={() => {
                        dispatch(toggleBody('homeCowsay'))
                        dispatch(
                            toggleCommand('emptyCommand')
                        )
                    }}
                />
            </Styled.CowsayCommand>
            {isPhrase && (
                <Styled.CowsayPhrase>
                    {'  '}
                    --------------
                    <br />
                    {' | thanks dude! | '}
                    <br />
                    {'  '}
                    --------------
                    <br />
                    {'        \\ '}
                    <br />
                    {'         \\    ^__^'}
                    <br />
                    {'          \\   (oo)\\__________'}
                    <br />
                    {'              (__)\\          )\\/\\'}
                    <br />
                    {'                  ||----- W- |'}
                    <br />
                    {'                  ||        ||'}
                </Styled.CowsayPhrase>
            )}
        </Styled.CowsayWrapper>
    )
}

export default Cowsay
