import React from 'react'
import * as Styled from './styled'
import Tutorial from '@containers/tutorial'

const TerminalBody = () => {
    return (
        <Styled.TerminalBodyWrapper>
            <Tutorial />
        </Styled.TerminalBodyWrapper>
    )
}

export default TerminalBody
