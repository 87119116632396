import styled from 'styled-components'

export const CdHomeWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const CdHomeCommand = styled.div`
    display: flex;
`
