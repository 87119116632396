export const getTutorial = state => state.tutorial
export const getTerminalInfo = state => state.terminalInfo
export const getUiInfo = state => state.uiInfo

// Tutorial
export const getLsHome = state => getTutorial(state).lsHome
export const isLsHomeCommandShown = state =>
    getLsHome(state).isCommandShown
export const isLsHomeBodyShown = state =>
    getLsHome(state).isBodyShown

export const getCatAbout = state =>
    getTutorial(state).catAbout
export const isCatAboutCommandShown = state =>
    getCatAbout(state).isCommandShown
export const isCatAboutBodyShown = state =>
    getCatAbout(state).isBodyShown

export const getCatSocial = state =>
    getTutorial(state).catSocial
export const isCatSocialCommandShown = state =>
    getCatSocial(state).isCommandShown
export const isCatSocialBodyShown = state =>
    getCatSocial(state).isBodyShown

export const getCdWorkingExperience = state =>
    getTutorial(state).cdWorkingExperience
export const isCdWorkingExperienceCommandShown = state =>
    getCdWorkingExperience(state).isCommandShown
export const isCdWorkingExperienceBodyShown = state =>
    getCdWorkingExperience(state).isBodyShown

export const getLsWorkingExperience = state =>
    getTutorial(state).lsWorkingExperience
export const isLsWorkingExperienceCommandShown = state =>
    getLsWorkingExperience(state).isCommandShown
export const isLsWorkingExperienceBodyShown = state =>
    getLsWorkingExperience(state).isBodyShown

export const getCdHome = state => getTutorial(state).cdHome
export const isCdHomeCommandShown = state =>
    getCdHome(state).isCommandShown
export const isCdHomeBodyShown = state =>
    getCdHome(state).isBodyShown

export const getHomeCowsay = state =>
    getTutorial(state).homeCowsay
export const isHomeCowsayCommandShown = state =>
    getHomeCowsay(state).isCommandShown
export const isHomeCowsayBodyShown = state =>
    getHomeCowsay(state).isBodyShown

export const getEmptyCommand = state =>
    getTutorial(state).emptyCommand
export const isEmptyCommandCommandShown = state =>
    getEmptyCommand(state).isCommandShown
export const isEmptyCommandBodyShown = state =>
    getEmptyCommand(state).isBodyShown

// Terminal info
export const getTerminalInfoLocation = state =>
    getTerminalInfo(state).location
export const getTerminalInfoRootLocation = state =>
    getTerminalInfoLocation(state).root
export const getTerminalInfoCurrentLocation = state =>
    getTerminalInfoLocation(state).current

// UI info
export const isLoaderShown = state =>
    getUiInfo(state).isLoaderShown
