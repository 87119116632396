import React from 'react'
import * as Styled from './styled'
import Prefix from '@components/prefix'
import Command from '@components/command'
import { useDispatch, useSelector } from 'react-redux'
import {
    toggleCommand,
    changeLocation,
} from '@redux/actions'
import { getTerminalInfoRootLocation } from '@redux/selectors'

const CdHome = () => {
    const dispatch = useDispatch()
    const rootLocation = useSelector(
        getTerminalInfoRootLocation
    )

    return (
        <Styled.CdHomeWrapper>
            <Styled.CdHomeCommand>
                <Prefix />
                <Command
                    text="cd ../"
                    onFinish={() => {
                        dispatch(
                            toggleCommand('homeCowsay')
                        )
                        dispatch(
                            changeLocation(rootLocation)
                        )
                    }}
                />
            </Styled.CdHomeCommand>
        </Styled.CdHomeWrapper>
    )
}

export default CdHome
