import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isLoaderShown: true,
}

const uiInfoSlice = createSlice({
    name: 'ui-info',
    initialState,
    reducers: {
        showLoader: state => {
            state.isLoaderShown = true
        },
        hideLoader: state => {
            state.isLoaderShown = false
        },
    },
})

export const { showLoader, hideLoader } =
    uiInfoSlice.actions
export const { reducer: uiInfo } = uiInfoSlice
