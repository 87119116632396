import styled from 'styled-components'

export const CdWorkWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const CdWorkCommand = styled.div`
    display: flex;
`
