import styled from 'styled-components'

export const PrefixWrapper = styled.div`
    display: flex;
    margin-right: 12px;
`

export const PrefixName = styled.div`
    color: #00ff00;
`

export const PrefixColumn = styled.div`
    color: #fff;
`

export const PrefixTilda = styled.div`
    color: #ff0096;
`

export const PrefixDollar = styled.div`
    color: #00bbcc;
`
