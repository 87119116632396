import React from 'react'
import * as Styled from './styled'
import TerminalHeader from '@containers/terminal-window/header'
import TerminalBody from '@containers/terminal-window/body'

const TerminalWindow = () => {
    return (
        <Styled.TerminalWindowWrapper>
            <TerminalHeader />
            <TerminalBody />
        </Styled.TerminalWindowWrapper>
    )
}

export default TerminalWindow
