import styled from 'styled-components'

export const CowsayWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const CowsayCommand = styled.div`
    display: flex;
`

export const CowsayPhrase = styled.p`
    white-space: pre;
`
