import React from 'react'
import * as Styled from './styled'

const Prefix = () => {
    return (
        <Styled.PrefixWrapper>
            <Styled.PrefixName>
                paruyr@mamikonyan
            </Styled.PrefixName>
            <Styled.PrefixColumn>:</Styled.PrefixColumn>
            <Styled.PrefixTilda>~</Styled.PrefixTilda>
            <Styled.PrefixDollar>$</Styled.PrefixDollar>
        </Styled.PrefixWrapper>
    )
}

export default Prefix
