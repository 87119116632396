import { tutorial } from '@redux/slices/tutorial'
import { terminalInfo } from '@redux/slices/terminal-info'
import { uiInfo } from '@redux/slices/ui-info'

const reducers = {
    tutorial,
    terminalInfo,
    uiInfo,
}

export default reducers
