import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    lsHome: {
        isCommandShown: false,
        isBodyShown: false,
    },
    catAbout: {
        isCommandShown: false,
        isBodyShown: false,
    },
    catSocial: {
        isCommandShown: false,
        isBodyShown: false,
    },
    cdWorkingExperience: {
        isCommandShown: false,
        isBodyShown: false,
    },
    lsWorkingExperience: {
        isCommandShown: false,
        isBodyShown: false,
    },
    cdHome: {
        isCommandShown: false,
        isBodyShown: false,
    },
    homeCowsay: {
        isCommandShown: false,
        isBodyShown: false,
    },
    emptyCommand: {
        isCommandShown: false,
        isBodyShown: false,
    },
}

const rowsSlice = createSlice({
    name: 'tutorial',
    initialState,
    reducers: {
        toggleCommand: {
            reducer: (state, { payload }) => {
                state[payload].isCommandShown =
                    !state[payload].isCommandShown
            },
            prepare: payload => ({ payload }),
        },
        toggleBody: {
            reducer: (state, { payload }) => {
                state[payload].isBodyShown =
                    !state[payload].isBodyShown
            },
            prepare: payload => ({ payload }),
        },
    },
})

export const { toggleCommand, toggleBody } =
    rowsSlice.actions
export const { reducer: tutorial } = rowsSlice
