import styled from 'styled-components'

export const RowLSWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const RowLSWLineOneWrapper = styled.div`
    display: flex;
`

export const RowLSWLineTwoWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`

export const RowLSTextFile = styled.div`
    margin-right: 48px;
`

export const RowLSFolder = styled.div`
    margin-right: 48px;
    color: #3498db;
`
