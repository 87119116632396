import React from 'react'
import * as Styled from './styled'
import Command from '@components/command'
import Prefix from '@components/prefix'
import { useDispatch, useSelector } from 'react-redux'
import { isCatSocialBodyShown } from '@redux/selectors'
import { toggleBody, toggleCommand } from '@redux/actions'

const CatSocialLinks = () => {
    const dispatch = useDispatch()

    const isSLShown = useSelector(isCatSocialBodyShown)

    return (
        <Styled.CatSLWrapper>
            <Styled.CatSLCommand>
                <Prefix />
                <Command
                    text="cat Social_Links.txt"
                    onFinish={() => {
                        dispatch(toggleBody('catSocial'))
                        dispatch(
                            toggleCommand(
                                'cdWorkingExperience'
                            )
                        )
                    }}
                />
            </Styled.CatSLCommand>
            {isSLShown && (
                <Styled.CatSLText>
                    🏢{' '}
                    <Styled.CatSLHref
                        target="_blank"
                        href="https://www.linkedin.com/in/mam1konyan/"
                    >
                        LinkedIn
                    </Styled.CatSLHref>
                    <br />
                    <br />
                    💻{' '}
                    <Styled.CatSLHref
                        target="_blank"
                        href="https://github.com/mam1konyan"
                    >
                        Github
                    </Styled.CatSLHref>
                    <br />
                    <br />
                    🌐{' '}
                    <Styled.CatSLHref
                        target="_blank"
                        href="https://www.facebook.com/mam1konyan"
                    >
                        Facebook
                    </Styled.CatSLHref>
                    <br />
                    <br />
                    📸{' '}
                    <Styled.CatSLHref
                        target="_blank"
                        href="https://www.instagram.com/mam1konyan/"
                    >
                        Instagram
                    </Styled.CatSLHref>
                </Styled.CatSLText>
            )}
        </Styled.CatSLWrapper>
    )
}

export default CatSocialLinks
