import React, { useEffect } from 'react'
import * as Styled from './styled'
import TerminalWindow from '@containers/terminal-window'
import Loader from '@components/loader'
import { useDispatch, useSelector } from 'react-redux'
import { isLoaderShown } from '@redux/selectors'
import { hideLoader, toggleCommand } from '@redux/actions'

const Root = () => {
    const dispatch = useDispatch()
    const isLoader = useSelector(isLoaderShown)

    useEffect(() => {
        const timeout = setTimeout(() => {
            dispatch(hideLoader())
            dispatch(toggleCommand('lsHome'))
        }, 300)
        return () => clearTimeout(timeout)
    }, [dispatch])

    return (
        <Styled.RootWrapper>
            {isLoader && <Loader />}
            <TerminalWindow />
        </Styled.RootWrapper>
    )
}

export default Root
