import React from 'react'
import RowLS from '@containers/tutorial/commands/ls-home'
import CatAbout from '@containers/tutorial/commands/cat-about'
import CatSocialLinks from '@containers/tutorial/commands/cat-social-links'
import CdWorkingExperience from '@containers/tutorial/commands/cd-working-experience'
import LsWork from '@containers/tutorial/commands/ls-work'
import CdHome from '@containers/tutorial/commands/cd-home'
import Cowsay from '@containers/tutorial/commands/cowsay'
import LastRow from '@containers/tutorial/commands/last-row'
import { useSelector } from 'react-redux'
import {
    isCatAboutCommandShown,
    isCatSocialCommandShown,
    isCdHomeCommandShown,
    isCdWorkingExperienceCommandShown,
    isEmptyCommandCommandShown,
    isHomeCowsayCommandShown,
    isLsHomeCommandShown,
    isLsWorkingExperienceCommandShown,
} from '@redux/selectors'
import * as Styled from './styled'

const Tutorial = () => {
    const isLsHome = useSelector(isLsHomeCommandShown)
    const isCatAbout = useSelector(isCatAboutCommandShown)
    const isCatSL = useSelector(isCatSocialCommandShown)
    const isCdWork = useSelector(
        isCdWorkingExperienceCommandShown
    )
    const isCdHome = useSelector(isCdHomeCommandShown)
    const isLsWork = useSelector(
        isLsWorkingExperienceCommandShown
    )
    const isCowsay = useSelector(isHomeCowsayCommandShown)
    const emptyCommand = useSelector(
        isEmptyCommandCommandShown
    )

    return (
        <Styled.TutorialWrapper>
            {isLsHome && <RowLS />}
            {isCatAbout && <CatAbout />}
            {isCatSL && <CatSocialLinks />}
            {isCdWork && <CdWorkingExperience />}
            {isLsWork && <LsWork />}
            {isCdHome && <CdHome />}
            {isCowsay && <Cowsay />}
            {emptyCommand && <LastRow />}
        </Styled.TutorialWrapper>
    )
}

export default Tutorial
