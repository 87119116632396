import React from 'react'
import * as Styled from './styled'
import Prefix from '@components/prefix'
import Command from '@components/command'
import { useDispatch, useSelector } from 'react-redux'
import {
    toggleCommand,
    changeLocation,
} from '@redux/actions'
import { getTerminalInfoCurrentLocation } from '@redux/selectors'

const CdWorkingExperience = () => {
    const dispatch = useDispatch()
    const terminalLocation = useSelector(
        getTerminalInfoCurrentLocation
    )

    return (
        <Styled.CdWorkWrapper>
            <Styled.CdWorkCommand>
                <Prefix />
                <Command
                    text="cd Working_Experience"
                    onFinish={() => {
                        dispatch(
                            toggleCommand(
                                'lsWorkingExperience'
                            )
                        )
                        dispatch(
                            changeLocation(
                                `${terminalLocation}/Working_Experience`
                            )
                        )
                    }}
                />
            </Styled.CdWorkCommand>
        </Styled.CdWorkWrapper>
    )
}

export default CdWorkingExperience
