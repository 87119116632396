import styled from 'styled-components'

export const CatSLWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const CatSLCommand = styled.div`
    display: flex;
`

export const CatSLText = styled.p``

export const CatSLHref = styled.a`
    color: #9980fa;
`
