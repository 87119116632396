import React from 'react'
import ReactDOM from 'react-dom'
import Root from './containers/root'
import reportWebVitals from './reportWebVitals'
import GlobalStyles from './styled'
import { Provider } from 'react-redux'
import store from '@redux'
import { inject } from '@vercel/analytics'

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <GlobalStyles />
            <Root />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
inject()
